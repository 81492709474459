import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit{
  forgotPasswordForm: FormGroup;
  constructor(private fb: FormBuilder, private service: MasterService, private loaderService: LoaderService){
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }
  ngOnInit(): void {
      
  }
  onSubmit(){
    if(this.forgotPasswordForm.valid){
      this.loaderService.show();
      this.service.post('/api/account/forgot_password', this.forgotPasswordForm.value, null, 'https://lawyers-dev-api.thelawyerss.com/api/account/forgot_password').subscribe({
        next: (res: any) => {
          this.forgotPasswordForm.reset();
          alert(`Password reset email sent to ${this.forgotPasswordForm.value.email}`)
        }, error: (err: any) => {
          console.log(err);
          alert(err.error.error.errors[0])
          this.loaderService.hide();
        }, complete: () => {
          this.loaderService.hide();
        }
      })
    }
  }
}
