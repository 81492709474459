import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericService } from 'src/app/common/generic.service';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';
declare var bootstrap: any; // Declare bootstrap for modal management

@Component({
  selector: 'app-lawyer-details',
  templateUrl: './lawyer-details.component.html',
  styleUrls: ['./lawyer-details.component.scss']
})
export class LawyerDetailsComponent implements OnInit {
  lawyerName: string = '';
  lawyerId: number | string = '';
  userRating: any = 4.5;
  lawyerDetails: any = {};
  reviews: any = [];
  pricing: any = [];

  constructor(
    private route: ActivatedRoute,
    private genericService: GenericService,
    private masterService: MasterService,
    private loaderService: LoaderService
  ) {
    this.userMasterId = localStorage.getItem('userMasterId');
  }

  ngOnInit(): void {
    // Retrieve the 'slug' parameter from the URL
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');

      if (slug) {
        // Use the GenericService to extract the name and id
        const { name, id } = this.genericService.reverseParamFromSEO(slug);
        this.lawyerName = name;
        this.lawyerId = id;
      }
    });

    this.getLawyerDetails();
    this.getReviews();
    this.getPricing();
  }
  getLawyerDetails() {
    this.loaderService.show();
    // console.log('loader shown');
    
    this.masterService.get('lawyer/' + this.lawyerId + '/profile').subscribe({
      next: (res: any) => {
        if(res && res.isSuccess == true){
          this.lawyerDetails = res.data;
        }
        // console.log(this.lawyerDetails);
        
      }, error: (err: any) => {
        console.log(err);
        this.loaderService.hide();
      }, complete: () => {
        this.loaderService.hide();
      }
    })
  }

  getReviews(){
    this.masterService.getReviews(this.lawyerId).subscribe({
      next: (res: any) => {
        if(res && res.isSuccess == true){
          this.reviews = res.data;
        }
        // console.log(this.reviews);
        
      }, error: (err: any) => {
        console.log(err);
        this.loaderService.hide();
      }, complete: () => {
        this.loaderService.hide();
      }
    })
  }

  getPricing(){
    this.masterService.getConsultingPrice(this.lawyerId).subscribe({
      next: (res: any) => {
        if(res && res.isSuccess == true){
          this.pricing = res.data;
        }
        // console.log(this.pricing);
        
      }, error: (err: any) => {
        console.log(err);
        this.loaderService.hide();
      }, complete: () => {
        this.loaderService.hide();
      }
    })
  }

  maxStars: number = 5;
  getStars(rating: number): { fullStars: number; halfStars: number; emptyStars: number } {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = this.maxStars - fullStars - halfStars;

    return {
      fullStars,
      halfStars,
      emptyStars
    };
  }
  selectedPricingItem: any = null;
  openModel(pricingItem: any){
    const today = new Date();
    this.minDate = today.toISOString().split('T')[0];
    this.selectedPricingItem = pricingItem;
    const modal = new bootstrap.Modal(document.getElementById('appointmentModal'));
    modal.show();
    
  }
  // booking appointment
  selectedDate: string | null = null;
  availableSlots: string[] = [];
  selectedSlot: string | null = null;
  isSlotsChecked: boolean = false;
  isFetchingSlots: boolean = false;
  errorMessage: any;
  checkAvailability() {
    this.isFetchingSlots = true;
    this.isSlotsChecked = false;
    this.errorMessage = '';
    this.availableSlots = [];

    this.masterService
      .get(`cust/available_slots/${this.lawyerId}?appointmentDate=${this.selectedDate}`)
      .subscribe({
        next: (res: any) => {
          if (res.isSuccess && res.data?.timeSlots?.length) {
            this.availableSlots = res.data.timeSlots;
          } else {
            this.errorMessage = res.message || 'No slots available.';
          }
        },
        error: (error: any) => {
          this.errorMessage = 'Failed to fetch available slots. Please try again later.';
          this.isFetchingSlots = false;
          console.error(error);
        },
        complete: () => {
          this.isFetchingSlots = false;
          this.isSlotsChecked = true;
        },
      });
  }
  
  // Format the time slot
  formatTime(slot: string): string {
    const date = new Date(slot);
    return date.toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true 
    });
  }
  formatSelectedDate(date: string | null): string {
    if (!date) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'long', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(date).toLocaleDateString('en-US', options);
  }
  

  // Select a time slot
  selectSlot(slot: string) {
    this.selectedSlot = slot;
  }
  userMasterId: string = '';
  minDate: string = '';
  // Mock booking functionality
  bookAppointment() {
    if (this.selectedSlot) {
      this.loaderService.show();
      const payload = {
        "customerUserId": parseInt(this.userMasterId),
        "lawyerUserId": this.lawyerId,
        "appointmentModeId": this.selectedPricingItem.appointmentTypeId,
        "appointmentDateTime": this.selectedSlot
      }
      this.masterService.post('cust/book_appointment', payload).subscribe({
        next: (res: any) => {

        }, error: (err: any) => {
          this.loaderService.hide();
          alert("Error in booking");
        }, complete: () => {
          this.loaderService.hide();
          alert('Appointment booked.')
          this.closeModal();
        }
      })      
    }
  }

  // Close the modal
  closeModal() {
    this.selectedDate = null;
    this.availableSlots = [];
    this.selectedSlot = null;
    this.isSlotsChecked = false;
    // Logic to close the modal
    const modal = bootstrap.Modal.getInstance(document.getElementById('appointmentModal'));
    modal.hide();
  }
}
