import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';
import { NavigationItem } from './navigation-item.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isLoggedIn: boolean = false;
  firstName: string = '';
  userMasterId: string = '';

  navigationItems: NavigationItem[] = [];

  //   navigationItems: NavigationItem[] = [
  //     {
  //         id: 1,
  //         title: "Home",
  //         icon: "",
  //         link: "/",
  //         objectDetailId: null,
  //         hasSubMenu: false,
  //         parentMenuId: null,
  //         subMenu: null
  //         // [
  //         //     {
  //         //         id: 2,
  //         //         title: "Home Page 1",
  //         //         icon: "",
  //         //         link: "/",
  //         //         objectDetailId: null,
  //         //         hasSubMenu: false,
  //         //         parentMenuId: 1,
  //         //         subMenu: null
  //         //     },
  //         //     {
  //         //         id: 3,
  //         //         title: "Home Page 2",
  //         //         icon: "",
  //         //         link: "/home-two",
  //         //         objectDetailId: null,
  //         //         hasSubMenu: false,
  //         //         parentMenuId: 1,
  //         //         subMenu: null
  //         //     },
  //         //     {
  //         //         id: 4,
  //         //         title: "Home Page 3",
  //         //         icon: "",
  //         //         link: "/home-three",
  //         //         objectDetailId: null,
  //         //         hasSubMenu: false,
  //         //         parentMenuId: 1,
  //         //         subMenu: null
  //         //     }
  //         // ]
  //     },
  //     {
  //         id: 5,
  //         title: "About",
  //         icon: "",
  //         link: "/about",
  //         objectDetailId: null,
  //         hasSubMenu: false,
  //         parentMenuId: null,
  //         subMenu: null
  //     },
  //     // {
  //     //     id: 6,
  //     //     title: "Attorneys",
  //     //     icon: "",
  //     //     link: "javascript:void(0)",
  //     //     objectDetailId: null,
  //     //     hasSubMenu: true,
  //     //     parentMenuId: null,
  //     //     subMenu: [
  //     //         {
  //     //             id: 7,
  //     //             title: "Attorney",
  //     //             icon: "",
  //     //             link: "/attorney",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 6,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 8,
  //     //             title: "Attorney Details",
  //     //             icon: "",
  //     //             link: "/attorney-details",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 6,
  //     //             subMenu: null
  //     //         }
  //     //     ]
  //     // },
  //     // {
  //     //     id: 9,
  //     //     title: "Case Studies",
  //     //     icon: "",
  //     //     link: "javascript:void(0)",
  //     //     objectDetailId: null,
  //     //     hasSubMenu: true,
  //     //     parentMenuId: null,
  //     //     subMenu: [
  //     //         {
  //     //             id: 10,
  //     //             title: "Case Study",
  //     //             icon: "",
  //     //             link: "/case-study",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 9,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 11,
  //     //             title: "Case Study Details",
  //     //             icon: "",
  //     //             link: "/case-study-details",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 9,
  //     //             subMenu: null
  //     //         }
  //     //     ]
  //     // },
  //     // {
  //     //     id: 12,
  //     //     title: "Blogs",
  //     //     icon: "",
  //     //     link: "javascript:void(0)",
  //     //     objectDetailId: null,
  //     //     hasSubMenu: true,
  //     //     parentMenuId: null,
  //     //     subMenu: [
  //     //         {
  //     //             id: 13,
  //     //             title: "Blog",
  //     //             icon: "",
  //     //             link: "/blog",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 12,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 14,
  //     //             title: "Blog Details",
  //     //             icon: "",
  //     //             link: "/blog-details",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 12,
  //     //             subMenu: null
  //     //         }
  //     //     ]
  //     // },
  //     // {
  //     //     id: 15,
  //     //     title: "Pages",
  //     //     icon: "",
  //     //     link: "javascript:void(0)",
  //     //     objectDetailId: null,
  //     //     hasSubMenu: true,
  //     //     parentMenuId: null,
  //     //     subMenu: [
  //     //         {
  //     //             id: 16,
  //     //             title: "Practice",
  //     //             icon: "",
  //     //             link: "/practice",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 17,
  //     //             title: "Practice Details",
  //     //             icon: "",
  //     //             link: "/practice-details",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 18,
  //     //             title: "Appointment",
  //     //             icon: "",
  //     //             link: "/appointment",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 19,
  //     //             title: "Testimonial",
  //     //             icon: "",
  //     //             link: "/testimonial",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 20,
  //     //             title: "FAQ",
  //     //             icon: "",
  //     //             link: "/faq",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 21,
  //     //             title: "404 Error",
  //     //             icon: "",
  //     //             link: "/error",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 22,
  //     //             title: "Privacy Policy",
  //     //             icon: "",
  //     //             link: "/privacy-policy",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         },
  //     //         {
  //     //             id: 23,
  //     //             title: "Terms & Conditions",
  //     //             icon: "",
  //     //             link: "/terms-conditions",
  //     //             objectDetailId: null,
  //     //             hasSubMenu: false,
  //     //             parentMenuId: 15,
  //     //             subMenu: null
  //     //         }
  //     //     ]
  //     // },
  //     {
  //         id: 24,
  //         title: "Contact",
  //         icon: "",
  //         link: "/contact",
  //         objectDetailId: null,
  //         hasSubMenu: false,
  //         parentMenuId: null,
  //         subMenu: null
  //     }
  // ];

  constructor(private router: Router, private loaderService: LoaderService, private service: MasterService) { }

  ngOnInit(): void {
    this.checkLoginStatusAndGetNavbar();

  }
  checkLoginStatusAndGetNavbar(): void {
    this.userMasterId = localStorage.getItem('userMasterId');
    this.firstName = localStorage.getItem('firstName');
    this.isLoggedIn = !!this.userMasterId; // If userMasterId exists, set isLoggedIn to true
    this.loaderService.show();
    // this.service.getMenu(this.userMasterId).subscribe({
    //   next: (res: any) => {
    //     if(res && res.isSuccess == true){
    //       // set navbar items
    //       // console.log(res.data);
    //       res.data.forEach(element => {
    //         this.navigationItems.push(element);  
    //       });
    //     }
    //   }, error: (err: any) => {
    //     this.loaderService.hide();
    //   }, complete: () => {
    //     this.loaderService.hide();
    //   }
    // })
    const storedMenu = JSON.parse(localStorage.getItem('navigationItems') || 'null');

    if (this.isLoggedIn && storedMenu) {
      // Use menu items from localStorage
      this.navigationItems = storedMenu;
    } else {
      // Fetch menu items again and store in localStorage
      this.loaderService.show();
      this.service.getMenu(this.userMasterId).subscribe({
        next: (res: any) => {
          if (res && res.isSuccess === true) {
            // Set navigation items and store in localStorage
            this.navigationItems = res.data;
            // localStorage.setItem('navigationItems', JSON.stringify(res.data));
          }
        },
        error: (err: any) => {
          this.loaderService.hide();
        },
        complete: () => {
          this.loaderService.hide();
        }
      });
    }
  }

  logout(): void {
    localStorage.clear();
    this.isLoggedIn = false;
    // this.router.navigate(['/login']); // Redirect to login page after logout
    window.location.href = '/'
  }
}
