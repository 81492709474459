<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Lawyer Details</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Lawyers</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>{{lawyerName}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" style="margin-top: 1rem; margin-bottom: 1rem;">
    <!-- <div class="row">
        <div style="padding-left: 10%; padding-right: 10%;background-image: url(https://res.cloudinary.com/vkeel/image/upload/v1649268519/Vkeel-New/giammarco-zeH-ljawHtg-unsplash.jpg); background-repeat: no-repeat; background-size: cover; height: 200px;">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <img [src]="lawyerDetails.profilePicUrl" alt="profile_img" class="rounded-circle  profile-img">
                    </div>
                    <div class="col-md-9">
                        <h5 class="mb-0">{{lawyerDetails?.lawyerFullName}}</h5>
                        <p class="text-small mb-0">{{lawyerDetails.serviceCities?.join(", ")}}</p>
                        <div class="rating">
                            <p>{{lawyerDetails.userRating}}</p>
                            <div class="rating-stars">
                                <ng-container *ngFor="let _ of [].constructor(getStars(userRating).fullStars)">
                                    <i class="zondicons--star-full"></i>
                                </ng-container>
                                <ng-container *ngFor="let _ of [].constructor(getStars(userRating).halfStars)">
                                    <i class="fa6-solid--star-half"></i>
                                </ng-container>
                                <ng-container *ngFor="let _ of [].constructor(getStars(userRating).emptyStars)">
                                    <i class="zondicons--star-empty"></i>
                                </ng-container>
                            </div>
                            <p>60 Client Ratings</p>
                            <div class="flag-div">
                                <i class="twemoji--flag-india"></i>
                                <p>India</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6"></div>
        </div>
    </div> -->
    <div class="row">
        <div class="background-opacity-container">
            <div class="col-md-6">
                <div class="row align-items-center">
                    <div class="col-md-3">
                        <img [src]="lawyerDetails.profilePicUrl" alt="profile_img" class="rounded-circle profile-img">
                    </div>
                    <div class="col-md-9">
                        <h5 class="mb-0">{{ lawyerDetails?.lawyerFullName }}</h5>
                        <p class="text-small mb-0">{{ lawyerDetails.serviceCities?.join(", ") }}</p>
                        <div class="rating">
                            <p style="background-color: #ffce39; color: white;  padding: 0px 5px; border-radius: 3px;">{{ lawyerDetails.userRating }}</p>
                            <div class="rating-stars">
                                <ng-container *ngFor="let _ of [].constructor(getStars(userRating).fullStars)">
                                    <i class="zondicons--star-full"></i>
                                </ng-container>
                                <ng-container *ngFor="let _ of [].constructor(getStars(userRating).halfStars)">
                                    <i class="fa6-solid--star-half"></i>
                                </ng-container>
                                <ng-container *ngFor="let _ of [].constructor(getStars(userRating).emptyStars)">
                                    <i class="zondicons--star-empty"></i>
                                </ng-container>
                            </div>
                            <p>60 Client Ratings</p>
                            <div class="flag-div">
                                <i class="twemoji--flag-india"></i>
                                <p>India</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6 d-flex justify-content-end">
                <div class="consultation-rate-main">
                    <p class="mb-1">Consultation Start From</p>
                    <h3>₹ 1045/-</h3>
                </div>
            </div> -->
        </div>
        <div class="container mt-3" style="max-width: 80% !important;">
            <div class="row">
                <div class="col-md-8">
                    <h4>About Me</h4>
                    <p>{{lawyerDetails.professionalBiography ? lawyerDetails.professionalBiography : "NA"}}</p>
    
                    <h4>Language(s) Spoken</h4>
                    <p>- {{lawyerDetails.languagesSpoken ? lawyerDetails.languagesSpoken?.join(", ") : 'NA'}}</p>
    
                    <!-- <h4>Location</h4> -->
                    <!-- <p>MAP</p> -->
                    <!-- <h4>Location(s)</h4>
                    <p>- {{lawyerDetails.serviceCities ? lawyerDetails.serviceCities?.join(", ") : 'NA'}}</p>    -->
    
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <i class="ic--outline-business"></i> &nbsp;&nbsp; {{lawyerDetails.lawyerFullName ? lawyerDetails.lawyerFullName : 'NA'}} Reviews
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <div *ngFor="let item of reviews">
                                <h5>{{item.customerName}}</h5>
                                <div class="rating">
                                    <span style="background-color: #ffce39; color: white;  padding: 0px 5px; border-radius: 3px;">{{item.rating}}</span>
                                    <div class="rating-stars ms-3">
                                        <ng-container *ngFor="let _ of [].constructor(getStars(item.rating).fullStars)">
                                            <i class="zondicons--star-full"></i>
                                        </ng-container>
                                        <ng-container *ngFor="let _ of [].constructor(getStars(item.rating).halfStars)">
                                            <i class="fa6-solid--star-half"></i>
                                        </ng-container>
                                        <ng-container *ngFor="let _ of [].constructor(getStars(item.rating).emptyStars)">
                                            <i class="zondicons--star-empty"></i>
                                        </ng-container>
                                    </div>
                                    <div class="review-date" style="display: flex; align-items: center; color: gray; gap: 10px; margin-left: 1.5rem;">
                                        <i class="simple-line-icons--calender"></i>
                                        <span>{{ item.createdOnUtc | date:'MMMM d, yyyy' }}</span>
                                    </div>
                                </div>
                                <span style=" display: block;margin-top: 0.5rem !important;">{{item.comments}}</span>
                                <hr>
                            </div>
                            </div>
                          </div>
                        </div>
                        </div>
                </div>
                <div class="col-md-4">
                    <div class="exp-ans-rates">
                        <div class="exp-ans-rates-box">
                            <h6>125</h6>
                            <p>Answers</p>
                        </div>
                        <div class="exp-ans-rates-box bl">
                            <h6>{{lawyerDetails.profExperience ? lawyerDetails.profExperience + "+ Yrs" : 'NA'}}</h6>
                            <p>Experience</p>
                        </div>
                        <div class="exp-ans-rates-box bl">
                            <h6>5 Stars</h6>
                            <p>Ratings</p>
                        </div>
                    </div>
                    <div *ngFor="let item of pricing;" class="consult-book-box">
                        <h6 class="d-flex align-items-center gap-2" style="color: #b69d74; font-weight: 400;">
                            <i class="material-symbols--mic-outline">
                        </i>{{item.appointmentType}}</h6>
                        <div class="price-discount">
                            <!-- <p style="color: gray">₹ {{item.price}}/-</p> -->
                            <p>₹ {{item.price}}/-</p>
                            <!-- <p class="chip-discount">Save 10%</p> -->
                        </div>
                        <p style="font-size: 0.9rem; font-weight: 400; color: rgb(98, 98, 98);">
                            30 Minutes {{item.appointmentType}}</p>
                        <button class="btn lufz-btn w-100" type="button" (click)="openModel(item)">Book now</button>
                    </div>
                    <!-- <div class="consult-book-box">
                        <h6 class="d-flex align-items-center gap-2" style="color: #b69d74; font-weight: 400;"><i class="material-symbols--mic-outline"></i> Voice Consultation</h6>
                        <div class="price-discount">
                            <p style="color: gray">₹ 1200/-</p>
                            <p>₹ 1100/-</p>
                            <p class="chip-discount">Save 10%</p>
                        </div>
                        <p style="font-size: 0.9rem; font-weight: 400; color: rgb(98, 98, 98);">30 Minutes Voice Consultation</p>
                        <button class="btn lufz-btn w-100">Book now</button>
                    </div> -->
                    <!-- <div class="consult-book-box">
                        <h6 class="d-flex align-items-center gap-2" style="color: #b69d74; font-weight: 400;"><i class="ep--message"></i> Message Consultation</h6>
                        <div class="price-discount">
                            <p style="color: gray">₹ 1100/-</p>
                            <p>₹ 1040/-</p>
                            <p class="chip-discount">Save 5%</p>
                        </div>
                        <p style="font-size: 0.9rem; font-weight: 400; color: rgb(98, 98, 98);">2 Hours Message Consultation</p>
                        <button class="btn lufz-btn w-100">Book now</button>
                    </div> -->
                    <!-- <div class="consult-book-box">
                        <h6 class="d-flex align-items-center gap-2" style="color: #b69d74; font-weight: 400;"><i class="tabler--video"></i> Video Consultation</h6>
                        <div class="price-discount">
                            <p style="color: gray">₹ 1200/-</p>
                            <p>₹ 1140/-</p>
                            <p class="chip-discount">Save 5%</p>
                        </div>
                        <p style="font-size: 0.9rem; font-weight: 400; color: rgb(98, 98, 98);">30 Minutes Video Consultation</p>
                        <button class="btn lufz-btn w-100">Book now</button>
                    </div> -->
                    <!-- <div class="consult-book-box">
                        <h6 class="d-flex align-items-center gap-2" style="color: #b69d74; font-weight: 400;"><i class="guidance--meeting-room"></i> Meeting Consultation</h6>
                        <div class="price-discount">
                            <p style="color: gray">₹ 1500/-</p>
                            <p>₹ 1425/-</p>
                            <p class="chip-discount">Save 5%</p>
                        </div>
                        <p style="font-size: 0.9rem; font-weight: 400; color: rgb(98, 98, 98);">1-2 Hours Meeitng Consultation</p>
                        <button class="btn lufz-btn w-100">Book now</button>
                    </div> -->

                    <!-- <h3>Service Cities</h3>
                    <span *ngFor="let chip of getDisplayedChips(lawyer.practiceAreasTags).chips" class="custom-chip">
                        {{ chip }}
                    </span> -->
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="modal fade" id="appointmentModal" tabindex="-1" aria-labelledby="appointmentModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-between">
          <h5 class="modal-title" id="appointmentModalLabel">{{selectedPricingItem?.appointmentType}}</h5>
          <button type="button" class="close" style="background: none; outline: none; border: none; font-size: 30px; color: #b0b0b0; font-weight: 600;" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Appointment Date Input -->
          <div class="form-group mb-3">
            <label for="appointmentDate">Select Appointment Date</label>
            <div class="d-flex align-items-center">
              <input
                type="date"
                id="appointmentDate"
                class="form-control me-2"
                [(ngModel)]="selectedDate"
                [min]="minDate"
              />
              <button type="button" class="btn lufz-btn m-2" [disabled]="!selectedDate" style="height: 40px; font-size: 12px; padding: 0px; width: 130px;" (click)="checkAvailability()">Check Slots</button>
            </div>
          </div>
  
          <!-- Available Slots Section -->
        <div *ngIf="isFetchingSlots" class="d-flex justify-content-center align-items-center mt-3">
            <div class="spinner"></div>
        </div>
        
        <div *ngIf="!isFetchingSlots && availableSlots?.length > 0" class="mt-3">
            <h6 style="font-weight: normal;">Available Slots for <span style="font-style: italic;">{{ formatSelectedDate(selectedDate) }}</span></h6>
            <div class="row">
            <div *ngFor="let slot of availableSlots" class="col-6 col-md-4 mb-2">
                <button
                    type="button"
                    class="btn btn-outline-success w-100"
                    [class.active]="slot === selectedSlot"
                    (click)="selectSlot(slot)"
                    style="text-transform: uppercase;"
                    >
                    {{ formatTime(slot) }}
                </button>
            </div>
            </div>
        </div>
        
        <!-- No Slots Available -->
        <div *ngIf="!isFetchingSlots && !availableSlots?.length && isSlotsChecked" class="text-danger mt-3" style="font-size: 1rem;">
            No slots available for the selected date.
        </div>
  
        </div>
        
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel </button>
          <button
            type="button"
            class="btn lufz-btn"
            style="height: 40px; border-radius: 8px; padding: 0px 1rem;"
            [disabled]="!selectedSlot"
            (click)="bookAppointment()"
          >
            Book Appointment
          </button>
        </div>
      </div>
    </div>
  </div>
  