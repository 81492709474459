<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Login</h2>


            </div>
        </div>
    </div>
</div>
<div class="contact-form contact-form-four pb-100" style="background-color: #333333;">
    <div class="loaction-area">

        <!-- Form container with reactive form -->
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="row contact-wrap flex-column align-content-center">

                <!-- Username input -->
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" formControlName="username" id="username" class="form-control" required
                            placeholder="Username">
                    </div>
                </div>

                <!-- Password input with toggle visibility -->
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group position-relative">
                        <input [type]="passwordFieldType" formControlName="password" id="password" class="form-control"
                            placeholder="Password"
                            [ngClass]="{'border-danger': loginForm.get('password').touched && loginForm.get('password').invalid}" />
                        <i class="flaticon-eye position-absolute"
                            [ngClass]="{ 'flaticon-eye-open': !hidePassword, 'flaticon-eye-close': hidePassword }"
                            style="cursor: pointer; right: 25px; top: 50%; transform: translateY(-50%);"
                            (click)="togglePasswordVisibility()"></i>
                    </div>
                    <!-- Custom error messages for password field -->
                    <div *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid"
                        class="error-messages">
                        <small *ngIf="loginForm.get('password').errors?.required" class="text-danger">
                            Password is required.
                        </small>
                        <small *ngIf="loginForm.get('password').errors?.minlength" class="text-danger">
                            Password must be at least 8 characters long.
                        </small>
                        <!-- <small *ngIf="loginForm.get('password').errors?.noNumber" class="text-danger">
                                    {{ loginForm.get('password').errors?.noNumber }}
                                </small>
                                <small *ngIf="loginForm.get('password').errors?.noUpperCase" class="text-danger">
                                    {{ loginForm.get('password').errors?.noUpperCase }}
                                </small>
                                <small *ngIf="loginForm.get('password').errors?.noLowerCase" class="text-danger">
                                    {{ loginForm.get('password').errors?.noLowerCase }}
                                </small>
                                <small *ngIf="loginForm.get('password').errors?.noSpecialCharacter" class="text-danger">
                                    {{ loginForm.get('password').errors?.noSpecialCharacter }}
                                </small> -->
                    </div>
                    <div class="invalid-form" style="display: flex; justify-content: center;">
                        <small *ngIf="invalidForm" style="color: red; margin-bottom: 1rem;">
                            {{invalidFormError}}
                        </small>
                    </div>
                </div>

                <!-- Custom switch button: Are you a Lawyer? -->
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group d-flex align-items-center">
                        <label for="customSwitch" class="mr-3 text-light">Are you a Lawyer?</label>
                        <label class="custom-switch">
                            <input type="checkbox" id="customSwitch" formControlName="isLawyer" />
                            <span class="custom-slider"></span>
                        </label>
                    </div>
                </div>

                <!-- Login button -->
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group text-center">
                        <button type="submit" class="btn lufz-btn" [disabled]="!loginForm.valid">Login</button>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group text-center">
                        <a [routerLink]="['/forgot-password']" style="color: white;">Forgot Password?</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- <div class="contact-form contact-form-four pb-100">
    <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>2108-267 Road Quadra,Toronto, Victiria Canada</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>Phone</h3>

                        <ul>
                            <li><a href="tel:+0755543332322">+07 5554 3332 322</a></li>
                            <li><a href="tel:+0555962435736">+05 5596 2435 736</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        
                        <h3>Email</h3>

                        <ul>
                            <li><a href="mailto:hello#64;lufz.com">hello&#64;lufz.com</a></li>
                            <li><a href="mailto:info#64;lufz.com">info&#64;lufz.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="Case Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn">Submit Case</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->