import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  baseUrl: string = 'https://lawyers-dev-api.thelawyerss.com/api/v1';

  constructor(protected http: HttpClient, private router: Router) { }

  get<T>(endpoint: string, params?: any, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.get<T>(apiUrl, { params });
  }

  post<T>(endpoint: string, body: any, headers?: HttpHeaders, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.post<T>(apiUrl, body, { headers });
  }

  put<T>(endpoint: string, body: any, headers?: HttpHeaders, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.put<T>(apiUrl, body, { headers });
  }

  delete<T>(endpoint: string, body?: any, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.delete<T>(apiUrl, { body: body });
  }

  getMenu<T>(userMasterId: string): Observable<T> {
    return this.http.get<T>(`https://lawyers-dev-api.thelawyerss.com/api/menu/${userMasterId ?? 0}`);
  }

  getAppointments(lawyerMasterId: string, searchText: string, pageNo: number, pageSize: number, fromDate: string | null, toDate: string | null, status: number = 0) {
    let params = `?pageNo=${pageNo}&pageSize=${pageSize}`;

    if (searchText) params += `&searchText=${searchText}`;
    if (fromDate) params += `&fromDate=${fromDate}`;
    if (toDate) params += `&toDate=${toDate}`;
    if (status && status !== 0) params += `&appointmentStatusId=${status}`;
    return this.http.get(`${this.baseUrl}/lawyer/${lawyerMasterId}/appointments${params}`)
  }

  getLawyerCalendar(lawyerMasterId: string, startDate: string, endDate: string) {
    return this.http.get(`${this.baseUrl}/lawyer/calendar/${lawyerMasterId}?calendarStartDate=${startDate}&calendarEndDate=${endDate}`);
  }

  updateLawyerAppointment(lawyerAppointmentId: string, newAppointmentStatusId: number, message: string = '') {
    return this.http.put(`${this.baseUrl}/lawyer/appointment_action/${lawyerAppointmentId}`, {
      appointmentStatusId: newAppointmentStatusId,
      remarks: message
    });
  }
  getLawyersForConsult(office: string | null, practiceAreaId: number | null, pageNo: number = 1, pageSize: number = 10) {
    let params = `?pageNo=${pageNo}&pageSize=${pageSize}`;

    if (office) params += `&officeCity=${office}`;
    if (practiceAreaId) params += `&practiceAreadId=${practiceAreaId}`;

    return this.http.get(`${this.baseUrl}/lawyer/profiles${params}`);
  }

  getReviews(lawyerMasterId: any, pageNo: number = 1, pageSize: number = 10) {
    let params = `${lawyerMasterId}?pageNo=${pageNo}&pageSize=${pageSize}`;

    return this.http.get(`${this.baseUrl}/reviews/${params}`);
  }

  getConsultingPrice(lawyerUserId: any){
    return this.http.get(`${this.baseUrl}/appointment_pricing/${lawyerUserId}`);
  }


  private readonly sessionTimeout = 3600000;
  private sessionTimer: any;

  startSessionTimer() {
    this.clearSessionTimer();

    // Set a timer for session expiry
    this.sessionTimer = setTimeout(() => {
      this.logout();
    }, this.sessionTimeout);
  }

  clearSessionTimer() {
    if (this.sessionTimer) {
      clearTimeout(this.sessionTimer);
      this.sessionTimer = null;
    }
  }

  logout() {
    // Clear storage and redirect to login
    localStorage.clear();
    this.clearSessionTimer();
    this.router.navigate(['/login']);
  }
  isSessionExpired(): boolean {
    const loginTime = Number(localStorage.getItem('loginTime'));
    const currentTime = Date.now();
    return loginTime ? currentTime - loginTime > this.sessionTimeout : true;
  }

  handleSessionValidation() {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      // No user is logged in, so skip validation
      return;
    }
    if (this.isSessionExpired()) {
      this.logout();
    } else {
      this.startSessionTimer();
    }
  }

}