<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Forgot Password</h2>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-four pb-100" style="background-color: #333333;">
    <div class="loaction-area">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
            <div class="row contact-wrap flex-column align-content-center">

                <!-- Email input -->
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" formControlName="email" id="email" class="form-control"
                            placeholder="Email">
                        <!-- Validation Error Messages -->
                        <div *ngIf="forgotPasswordForm.get('email')?.touched && forgotPasswordForm.get('email')?.invalid">
                            <small class="text-danger" *ngIf="forgotPasswordForm.get('email')?.errors?.['required']">
                                Email is required.
                            </small>
                            <small class="text-danger" *ngIf="forgotPasswordForm.get('email')?.errors?.['email']">
                                Please enter a valid email address.
                            </small>
                        </div>
                    </div>
                </div>

                <!-- Send Email Button -->
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group text-center">
                        <button type="submit" class="btn lufz-btn" [disabled]="!forgotPasswordForm.valid">
                            Send Reset Email
                        </button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>
