import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hidePassword = true;
  passwordFieldType = 'password';
  invalidForm: boolean = false;
  invalidFormError: string = '';

  constructor(private fb: FormBuilder, private service: MasterService, private loaderService: LoaderService, private router: Router) { }

  ngOnInit(): void {
    // Define the reactive form with validation rules
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      // password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator()]],
      isLawyer: [false]
    });

    this.loginForm.get('username').valueChanges.subscribe(() => {
      this.invalidForm = false;
      this.invalidFormError = '';
    })
    this.loginForm.get('password').valueChanges.subscribe(() => {
      this.invalidForm = false;
      this.invalidFormError = '';
    })
    this.loginForm.get('isLawyer').valueChanges.subscribe(() => {
      this.invalidForm = false;
      this.invalidFormError = '';
    })
  }
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const hasNumber = /\d/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSpecialCharacter = /[!?\*@s%]/.test(value);

      const errors: ValidationErrors = {};

      if (!hasNumber) {
        errors['noNumber'] = 'Your password must contain at least one numeric digit.';
      }
      if (!hasUpperCase) {
        errors['noUpperCase'] = 'Your password must contain at least one uppercase letter.';
      }
      if (!hasLowerCase) {
        errors['noLowerCase'] = 'Your password must contain at least one lowercase letter.';
      }
      if (!hasSpecialCharacter) {
        errors['noSpecialCharacter'] = 'Your password must contain at least one (!?*@s%).';
      }

      return Object.keys(errors).length ? errors : null;
    };
  }
  // Toggle password visibility
  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
    this.passwordFieldType = this.hidePassword ? 'password' : 'text';
  }

  // Handle form submission
  onSubmit(): void {
    if (this.loginForm.valid) {
      const formData = this.loginForm.value;
      this.loaderService.show();
      if(formData.isLawyer){
        // Login Lawyer
        this.service.post('sign_in/lawyer', {
          "userName": formData.username,
          "password": formData.password
        }).subscribe({
          next: (response: any) => {           
            if(response && response.isSuccess == true){
              localStorage.setItem('userMasterId', response.data.userMasterId);
              localStorage.setItem('firstName', response.data.firstName);
              localStorage.setItem('lastName', response.data.lastName);
              localStorage.setItem('username', response.data.username);
              localStorage.setItem('email', response.data.email);
              localStorage.setItem('mobileNo', response.data.mobileNo);
              localStorage.setItem('accessToken', response.data.accessToken);
              localStorage.setItem('refreshToken', response.data.refreshToken);
              localStorage.setItem('role', 'Laywer');
              localStorage.setItem('loginTime',Date.now().toString());
            }
            this.service.getMenu(response.data.userMasterId).subscribe({
              next: (menuResponse: any) => {
                if (menuResponse && menuResponse.isSuccess == true) {
                  localStorage.setItem('navigationItems', JSON.stringify(menuResponse.data));
                }
              },
              error: (menuErr) => {
                console.error('Failed to fetch menu', menuErr);
              }
            });

            this.service.startSessionTimer();
          }, error: (err) => {
            this.loaderService.hide();
            console.log(err);
            
            this.invalidForm = true;
            this.invalidFormError = err.error.error.errors[0]
          }, complete: () => {
            this.loaderService.hide();
            window.location.href = '/calendar/:lawyerMasterId'; //for lawyer redirect to dashboard (Calender)
          }
        })
      }else{
        // Login Customer
        this.service.post('sign_in/cust', {
          "userName": formData.username,
          "password": formData.password
        }).subscribe({
          next: (response: any) => {           
            if(response && response.isSuccess == true){
              localStorage.setItem('userMasterId', response.data.userMasterId);
              localStorage.setItem('firstName', response.data.firstName);
              localStorage.setItem('lastName', response.data.lastName);
              localStorage.setItem('username', response.data.username);
              localStorage.setItem('email', response.data.email);
              localStorage.setItem('mobileNo', response.data.mobileNo);
              localStorage.setItem('accessToken', response.data.accessToken);
              localStorage.setItem('refreshToken', response.data.refreshToken);
              localStorage.setItem('role', 'Customer');
              localStorage.setItem('loginTime',Date.now().toString());
            }
            this.service.getMenu(response.data.userMasterId).subscribe({
              next: (menuResponse: any) => {
                if (menuResponse && menuResponse.isSuccess == true) {
                  localStorage.setItem('navigationItems', JSON.stringify(menuResponse.data));
                }
              },
              error: (menuErr) => {
                console.error('Failed to fetch menu', menuErr);
              }
            });

            this.service.startSessionTimer();
          }, error: (err) => {
            this.loaderService.hide();
            console.log(err);
            
            this.invalidForm = true;
            this.invalidFormError = err.error.error.errors[0]
          }, complete: () => {
            this.loaderService.hide();
            window.location.href = '/'; //for customer redirect to dashboard (Route pending)
          }
        })
      }
    }
  }
}
