<div class="page-title-area page-title-area-three title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>About Our Firm and Our Legacy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About Us</li>
                </ul>

                <div class="page-title-btn">
                    <a routerLink="/testimonial">ALL CERTIFICATES<i class="icofont-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-us-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-us-content">
                    <h2>About Us</h2>
                    <p>Welcome to our Online Legal Platform, a new and innovative service designed to make legal help easy and available to everyone across India. Our goal is to help individuals and businesses by making legal processes simpler and ensuring that everyone can access justice.</p>
                    <p>Our team of skilled legal experts is here to provide you with top-quality advice and support that fits your specific needs. We use the latest technology to make it simple and convenient for you to get reliable legal information and services from your home or workplace.</p>
                    <p>We value honesty, openness, and fairness. We aim to remove obstacles in the legal system and build a community where everyone can find the knowledge and help they need.</p>
                    <p>Join us in changing how legal services work in India. Let us help you find the solutions you need for a calm and law-abiding life.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="help-area help-area-two help-area-four pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/5.jpg" alt="Help">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Professional Attorneys Dedicated to Your Success</h2>
                        <p>Our team of experienced attorneys is committed to advocating for our clients’ rights with a results-driven approach and unwavering loyalty. We understand the complexities and challenges faced by our clients, and we strive to offer tailored legal services that cater to their unique needs. Whether navigating sensitive family matters, complex corporate disputes, or critical criminal defense, The Lawyerss is here to help you overcome the toughest moments with confidence and skill.</p>
                        <p>We are qualified for the success of our client & as a professional law firm. So these professional attorneys are still here at your side with kindness.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li>
                                    <i class="flaticon-checkmark"></i> Browse Our Expertise</li>
                                <li><i class="flaticon-checkmark"></i> Choose Your Legal Service</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Responsive Guidance</li>
                                <li><i class="flaticon-checkmark"></i> Exemplary Performance</li>
                            </ul>
                        </div>

                        <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div>

                        <h3>Rishi Malhotra</h3>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center help-wrap">
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Our Attorneys Always Provide The Excellent Service</h2>
                        <p>Teams of Intelligent & Professional Attorneys of Lyzo are always prepared to make you triumph. We always try our best to make our clients happy.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> On Time Response</li>
                                <li><i class="flaticon-checkmark"></i> Legal Way</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Legal Services</li>
                                <li><i class="flaticon-checkmark"></i> Honest Work</li>
                            </ul>
                        </div>

                        <a class="cmn-btn" routerLink="/practice">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/6.jpg" alt="Help">
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="expertise-area expertise-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>OUR EXPERTISE</span>
            <h2>Devoted to Delivering Exceptional Legal Services</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Unmatched Experience</h3>
                            <p>At The Lawyerss, our attorneys are not only skilled but also empathetic towards each client’s unique circumstances. We provide strategic counsel, helping clients achieve their objectives with diligence and professionalism.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Skilled Attorneys</h3>
                            <p>Our legal team consists of creative and knowledgeable attorneys who offer a wide range of solutions, ensuring that each case is handled with care and expertise. From complex litigation strategies to innovative approaches, we provide comprehensive legal support.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Legal Integrity</h3>
                            <p>We adhere strictly to legal procedures, maintaining accurate documentation and upholding the principles of justice and fairness in all our dealings. Every case is managed with transparency and accountability.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-money-bag"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Cost-Effective Solutions</h3>
                            <p>We aim to offer our clients the most cost-effective legal services without compromising on quality. Our commitment to affordability ensures that legal excellence is accessible to all our clients.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-time"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Client-Centric Approach</h3>
                            <p>Our attorneys are dedicated to delivering high-quality service by understanding each client’s needs and concerns, providing timely updates, and achieving the best possible outcomes.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-conversation"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Efficient Processes</h3>
                            <p>We manage all documentation and legal proceedings with precision and efficiency, ensuring a seamless and lawful journey toward success.</p>
                        </li>
                    </ul>
               </div>
            </div>
        </div>
    </div>
</section>

<div class="about-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-video-wrap">
                        <div class="about-video">
                            <img src="assets/img/about/2.jpg" alt="About">
                        </div>
                        <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                    </div>

                    <div class="about-content">
                        <h2>Our Skills Make Us Stand Out</h2>
                        <p>At <strong>The Lawyerss</strong>, we have honed our expertise in delivering strategic and reliable legal solutions that cater to diverse legal needs. With our strong commitment to excellence and justice, we have established a reputation as a trusted partner in navigating complex legal landscapes. We take pride in our approach, which is characterized by integrity, empathy, and an unwavering focus on achieving the best outcomes for our clients.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-information">
                        <h2><span>21 Feb 1998,</span> The Beginning of Our Journey</h2>
                        <p><strong>The Lawyerss</strong> was founded with a vision to provide comprehensive and accessible legal services to individuals and businesses. From the very start, our focus has been on creating a firm that prioritizes client needs while maintaining the highest standards of professionalism and legal integrity.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>10 March 2000,</span> Expanding Our Legal Services</h2>
                        <p>In 2000, we expanded our practice areas to better serve our clients. This milestone enabled us to address a broader range of legal challenges and help individuals and corporations achieve favorable outcomes in their legal matters.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>9 Nov 2000,</span> Building a Strong Foundation</h2>
                        <p>With a growing team of highly skilled attorneys, we solidified our position as a leading law firm. Our collective expertise in various legal sectors and commitment to providing personalized attention became the cornerstone of our success.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>25 Jan 2010,</span> A Decade of Excellence</h2>
                        <p>After over a decade of dedication, <strong>The Lawyerss</strong> continues to set new benchmarks in the legal industry. Our firm remains committed to innovation, continuous learning, and exceeding client expectations by delivering outstanding legal representation.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="page-title-area page-title-area-three title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>About Our Firm and Our Legacy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About Us</li>
                </ul>

                <div class="page-title-btn">
                    <a routerLink="/testimonial">ALL CERTIFICATES<i class="icofont-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="help-area help-area-two help-area-four pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/5.jpg" alt="Help">
                </div>
            </div>
            <div class="
            col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Professional Attorneys Dedicated to Your Success</h2>
                        <p>Our team of experienced attorneys is committed to advocating for our clients’ rights with a results-driven approach and unwavering loyalty. We understand the complexities and challenges faced by our clients, and we strive to offer tailored legal services that cater to their unique needs. Whether navigating sensitive family matters, complex corporate disputes, or critical criminal defense, The Lawyerss is here to help you overcome the toughest moments with confidence and skill.</p>
                        <p>We are qualified for the success of our client & as a professional law firm. So these professional attorneys are still here at your side with kindness.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li>
                                    <i class="flaticon-checkmark"></i> Browse Our Expertise</li>
                                <li><i class="flaticon-checkmark"></i> Choose Your Legal Service</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Responsive Guidance</li>
                                <li><i class="flaticon-checkmark"></i> Exemplary Performance</li>
                            </ul>
                        </div>

                        <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div>

                        <h3>Rishi Malhotra</h3>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center help-wrap">
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Our Attorneys Always Provide The Excellent Service</h2>
                        <p>Teams of  Intelligent & Professional Attorneys of Lyzo are always prepared to make you triumph. We always try our best to make our clients happy.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> On Time Response</li>
                                <li><i class="flaticon-checkmark"></i> Legal Way</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Legal Services</li>
                                <li><i class="flaticon-checkmark"></i> Honest Work</li>
                            </ul>
                        </div>

                        <a class="cmn-btn" routerLink="/practice">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/6.jpg" alt="Help">
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="expertise-area expertise-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>OUR EXPERTISE</span>
            <h2>Devoted to Delivering Exceptional Legal Services</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Unmatched Experience</h3>
                            <p>At The Lawyerss, our attorneys are not only skilled but also empathetic towards each client’s unique circumstances. We provide strategic counsel, helping clients achieve their objectives with diligence and professionalism.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Skilled Attorneys</h3>
                            <p>Our legal team consists of creative and knowledgeable attorneys who offer a wide range of solutions, ensuring that each case is handled with care and expertise. From complex litigation strategies to innovative approaches, we provide comprehensive legal support.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Legal Integrity</h3>
                            <p>We adhere strictly to legal procedures, maintaining accurate documentation and upholding the principles of justice and fairness in all our dealings. Every case is managed with transparency and accountability.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-money-bag"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Cost-Effective Solutions</h3>
                            <p>We aim to offer our clients the most cost-effective legal services without compromising on quality. Our commitment to affordability ensures that legal excellence is accessible to all our clients.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-time"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Client-Centric Approach</h3>
                            <p>Our attorneys are dedicated to delivering high-quality service by understanding each client’s needs and concerns, providing timely updates, and achieving the best possible outcomes.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-conversation"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Efficient Processes</h3>
                            <p>We manage all documentation and legal proceedings with precision and efficiency, ensuring a seamless and lawful journey toward success.</p>
                        </li>
                    </ul>
               </div>
            </div>
        </div>
    </div>
</section>

<div class="about-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-video-wrap">
                        <div class="about-video">
                            <img src="assets/img/about/2.jpg" alt="About">
                        </div>
                        <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                    </div>

                    <div class="about-content">
                        <h2>Our Skills Make Us Stand Out</h2>
                        <p>At <strong>The Lawyerss</strong>, we have honed our expertise in delivering strategic and reliable legal solutions that cater to diverse legal needs. With our strong commitment to excellence and justice, we have established a reputation as a trusted partner in navigating complex legal landscapes. We take pride in our approach, which is characterized by integrity, empathy, and an unwavering focus on achieving the best outcomes for our clients.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-information">
                        <h2><span>21 Feb 1998,</span> The Beginning of Our Journey</h2>
                        <p><strong>The Lawyerss</strong> was founded with a vision to provide comprehensive and accessible legal services to individuals and businesses. From the very start, our focus has been on creating a firm that prioritizes client needs while maintaining the highest standards of professionalism and legal integrity.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>10 March 2000,</span> Expanding Our Legal Services</h2>
                        <p>In 2000, we expanded our practice areas to better serve our clients. This milestone enabled us to address a broader range of legal challenges and help individuals and corporations achieve favorable outcomes in their legal matters.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>9 Nov 2000,</span> Building a Strong Foundation</h2>
                        <p>With a growing team of highly skilled attorneys, we solidified our position as a leading law firm. Our collective expertise in various legal sectors and commitment to providing personalized attention became the cornerstone of our success.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>25 Jan 2010,</span> A Decade of Excellence</h2>
                        <p>After over a decade of dedication, <strong>The Lawyerss</strong> continues to set new benchmarks in the legal industry. Our firm remains committed to innovation, continuous learning, and exceeding client expectations by delivering outstanding legal representation.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
